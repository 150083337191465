/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import TurbolinksAdapter from 'vue-turbolinks'

import MobileNavigation from "../mobile-navigation.js";
import DesktopNavigation from "../desktop-navigation.js";
import InterviewSlider from "../interview-slider.js";
import Vue from 'vue/dist/vue.esm'
import InterviewFilter from '../components/InterviewFilter.vue'
import Chart from 'chart.js/auto';
// Add "turbolinks-request" class to the html tag when there's
// a request going on, applying cursor: progress; on the document
document.addEventListener('turbolinks:request-start', function () {
  document.documentElement.classList.add("turbolinks-request");
});

document.addEventListener("turbolinks:request-end", function () {
  document.documentElement.classList.remove("turbolinks-request");
});

// Javascript to run on load
document.addEventListener("turbolinks:load", function () {
  MobileNavigation();
  DesktopNavigation();
  InterviewSlider();

  const dummyLabels = ['2017', '2018', '2019', '2020', '2021', '2022'];
  const dummyScaleLabel = 'TestLabel Mnkr';
  const dummyData = [4, 5, 3, 7, 8, 7];
  const dummyColors = [
    'rgba(190,208,0, 1)',
    'rgba(188, 228, 250, 1)',
    'rgba(42,45,48, 1)',
    'rgba(190,208,0, 0.5)',
    'rgba(188, 228, 250, 0.5)',
    'rgba(42,45,48, 0.5)',
    'rgba(190,208,0, 0.75)',
    'rgba(188, 228, 250, 0.75)',
    'rgba(42,45,48, 0.75)',
    'rgba(190,208,0, 0.25)',
    'rgba(188, 228, 250, 0.25)',
    'rgba(42,45,48, 0.25)',
  ];

  var charts = document.querySelectorAll('.chart');

  charts.forEach((c) => {
    if(c.dataset.type == 'pie') {
      new Chart(c.getContext('2d'), {
        type: 'pie',
        data: {
          labels: JSON.parse(c.dataset.labels),
          datasets: [
            {
              data: JSON.parse(c.dataset.data),
              backgroundColor: JSON.parse(c.dataset.colors),
            }
          ],
        },
        options: {
          aspectRatio: 2,
          plugins: {
            legend: {
              position: c.dataset.position,
              align: "center",
            }
          },
          tooltips: {
            callbacks: {
              label (tooltipItem, data) {
                const label =
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                    ' ' || '';
                return label + 'Mnkr';
              },
            },
          },
        },
      });
    }
    if(c.dataset.type == 'bar') {
      new Chart(c.getContext('2d'), {
        type: 'bar',
        data: {
          labels: JSON.parse(c.dataset.labels),
          datasets: [
            {
              data: JSON.parse(c.dataset.data),
              backgroundColor: JSON.parse(c.dataset.colors),
            }
          ],
        },
        options: {
          plugins: {
          legend: false
        },
          scales: {
            yAxes: [{
              ticks: {
                  suggestedMin: 0,
              }
          }]

          },
        },
      });
    }
    if(c.dataset.type == 'line') {
      new Chart(c.getContext('2d'), {
        type: 'line',
        data: {
          labels: JSON.parse(c.dataset.labels),
          datasets: [
            {
              label: '',
              data: JSON.parse(c.dataset.data),
              borderColor: JSON.parse(c.dataset.colors),
              backgroundColor: JSON.parse(c.dataset.colors),
            },
          ],
        },
        options: {
          plugins: {
            legend: false
          },
          tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                      return tooltipItem.yLabel;
                }
            }
          },
          scales: {
            y: {
              display: true,
              title: {
                display: false,
                text: dummyScaleLabel,
              },
              grid: {
                display: false,
              },
            },
          },
        },
      });
    }
  })

  // var pieChartEl = document.getElementById('pieChart').getContext('2d');
  // var pieChart = new Chart(pieChartEl, {
  //   type: 'pie',
  //   data: {
  //     labels: dummyLabels,
  //     datasets: [
  //       {
  //         data: dummyData,
  //         backgroundColor: dummyColors,
  //       }
  //     ],
  //   },
  //   options: {
  //     tooltips: {
  //       callbacks: {
  //         label (tooltipItem, data) {
  //           const label =
  //             data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
  //               ' ' || '';
  //           return label + 'Mnkr';
  //         },
  //       },
  //     },
  //   },
  // });

  // var barChartEl = document.getElementById('barChart').getContext('2d');
  // var barChart = new Chart(barChartEl, {
  //   type: 'bar',
  //   data: {
  //     labels: dummyLabels,
  //     datasets: [
  //       {
  //         data: dummyData,
  //         backgroundColor: dummyColors,
  //       }
  //     ],
  //   },
  //   options: {
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //     scales: {
  //       y: {
  //         display: true,
  //         title: {
  //           display: true,
  //           text: dummyScaleLabel,
  //         },
  //         grid: {
  //           display: false,
  //         },
  //       },
  //       x: {
  //         grid: {
  //           display: false,
  //         },
  //       },
  //     },
  //   },
  // });

  // var lineChartEl = document.getElementById('lineChart').getContext('2d');
  // var lineChart = new Chart(lineChartEl, {
  //   type: 'line',
  //   data: {
  //     labels: dummyLabels,
  //     datasets: [
  //       {
  //         label: dummyScaleLabel,
  //         data: dummyData,
  //         borderColor: dummyColors[0],
  //         backgroundColor: dummyColors[0],
  //       },
  //     ],
  //   },
  //   options: {
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //     scales: {
  //       y: {
  //         display: true,
  //         title: {
  //           display: true,
  //           text: dummyScaleLabel,
  //         },
  //         grid: {
  //           display: false,
  //         },
  //       },
  //     },
  //   },
  // });



  // Stop playing Youtube video when VideoPlayerModal is closed
  // Yay for jQuery :)
  $('#videoPlayerModal').on('hide.bs.modal', function (e) {
    var $if = $(e.delegateTarget).find('iframe');
    var src = $if.attr("src");
    $if.attr("src", '/empty.html');
    $if.attr("src", src);
    console.log("hide");
  });


  // Johan Andrés jquery magic hehe

  var hash = window.location.hash;
  var elm = $(hash).next('.collapse').collapse();


  // Add class when page is scrolled
  window.addEventListener("scroll", pageScrollState);

  function pageScrollState () {
    if (window.scrollY > 8) {
      document.documentElement.classList.add("js-page-is-scrolled");
    } else {
      document.documentElement.classList.remove("js-page-is-scrolled");
    }
  }

  $('.tracked-link').on('click', function(event) {

    ga('send', 'event', {
      eventCategory: 'Outbound Link',
      eventAction: 'click',
      eventLabel: event.target.href,
      transport: 'beacon'
    });


  });


});



Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  var el = document.querySelector('[data-behavior="vue"]');
  if(el) {
    const app = new Vue({
      el: el,
      components: { InterviewFilter }
    })
  }
})
