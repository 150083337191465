export default () => {

  const nav = document.querySelector('.site-header');
  const navItems = document.querySelectorAll('.nav-item--has-dropdown .nav-link');
  const backdrop = document.querySelector('.site-header__backdrop');

  // loop through all links with dropdown, open/close on click
  for (var i = 0; i < navItems.length; i++) {
    navItems[i].addEventListener('click', function (e) {
      e.preventDefault();

      if (this.classList.contains('has-open-dropdown')) {
        closeDropdown();
      } else {
        openDropdown(this);
      }
    });
  };

  document.addEventListener("turbolinks:load", function () {
    closeDropdown();
  });

  // Close dropdown on backdrop click
  backdrop.addEventListener('click', function (e) {
    e.preventDefault();
    closeDropdown();
  });

  // open dropdown
  function openDropdown(el) {
    nav.classList.add('js-open-dropdown');
    setItemsInactive();

    // add .has-open-dropdown on the clicked item
    el.classList.add('has-open-dropdown');
    el.setAttribute("aria-expanded", "true");
  }

  // close dropdown
  function closeDropdown() {
    nav.classList.remove('js-open-dropdown');
    setItemsInactive();
  }


  // remove .has-open-dropdown on all li's
  function setItemsInactive() {
    for (i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('has-open-dropdown');
      navItems[i].setAttribute("aria-expanded", "false");
    }
  }
}
