import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';

export default () => {
  const elements = document.getElementsByClassName('interview-slider__inner');

  for(let s of elements) {

    tns({
      container: s,
      slideBy: 'page',
      autoplay: false,
      loop: false,
      mouseDrag: true,
      gutter: 30,
      nav: false,
      controlsPosition: 'bottom',
      controlsText: [
        '<span class="svg-icon font-size-base" aria-hidden="true" role="presentation"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4.27 11.25l6.61 6.62a1.25 1.25 0 0 1-1.76 1.76L.37 10.88a1.25 1.25 0 0 1 0-1.76L9.12.37a1.25 1.25 0 0 1 1.76 1.76L4.27 8.75h14.48a1.25 1.25 0 0 1 0 2.5H4.27z"/></svg></span>',
        '<span class="svg-icon font-size-base" aria-hidden="true" role="presentation"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15.73 8.75L9.12 2.13A1.25 1.25 0 0 1 10.88.37l8.75 8.75c.5.48.5 1.28 0 1.76l-8.75 8.75a1.25 1.25 0 0 1-1.76-1.76l6.61-6.62H1.25a1.25 1.25 0 0 1 0-2.5h14.48z"/></svg></span>'
      ],
      responsive: {
      // Make sure that these breakpoints is the same as the Bootstrap grid max widths
      "0": {
          items: 1.5,
          slideBy: 1
        },
        "576": {
          items: 2.25,
          slideBy: 2
        },
        "768": {
          items: 2.75,
        },
        "1024": {
          items: 2.5,
        },
        "1440": {
          items: 3,
        }
      },
      onInit() {
        document.querySelector('.tns-controls').setAttribute('tabindex', -1);
        document.querySelector('[data-controls="prev"]').setAttribute('tabindex', 0);
        document.querySelector('[data-controls="next"]').setAttribute('tabindex', 0);
      }
    });
  }
};
