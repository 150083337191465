<template>
  <div>
    <div id="top-of-list" class="row justify-content-end pt-vw-1">
      <div class="col-auto">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="badge badge-dark mr-2">{{selectedFilters.length}}</span>
            Filtrera på utbildning
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div class="px-3" style="min-width: 44rem;">

              <label v-for="(education, index) in educations" :key="education.id" @click.stop.prevent="toggle(index)" class="custom-btn-checkbox custom-btn-checkbox--primary mr-2 mt-2 mb-1">
                <input type="checkbox" :checked="education.selected">
                <span class="btn btn-sm">
                  {{education.name}}
                </span>
              </label>

            </div>
            <div class="dropdown-divider"></div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-light my-1 mr-3" type="button">
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filteredItems.length > 0" class="row pt-vw-1">

      <InterviewCard v-for="item in filteredItems.slice((this.onPage - 1) * this.perPage, ((this.onPage - 1) * this.perPage) + this.perPage)" :key="item.title" :interview="item"></InterviewCard>
    </div>
    <div v-else class="row pt-vw-1 justify-content-center font-weight-bold">
      <p>Inget att visa. Använd filtren för att få fler träffar!</p>
    </div>
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center font-weight-bold font-size-sm">
        <li v-for="(n, index) in numberOfPages" :key="index" class="page-item" :class="{ active: n == onPage}">
          <a class="page-link" href="#top-of-list" @click.prevent="navigateToPage(n, $event)">{{n}}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import { Vue, Component } from "vue-property-decorator";
  import axios from "axios";
  import InterviewCard from "./InterviewCard.vue";
  @Component({
    components: {
      InterviewCard
    }
  })
  export default class InterViewFilter extends Vue {
    educations = [];
    items = [];
    perPage = 12;
    onPage = 1;

    mounted() {
      this.fetchInterviews();
    }

    fetchInterviews() {
      axios
        .get("/api/interviews.json")
        .then(response => (this.items = response.data));

      axios
        .get("/api/educations.json")
        .then(response => (this.educations = response.data));
    }

    toggle(index) {
      this.educations[index].selected = !this.educations[index].selected;
      this.onPage = 1;
    }

    navigateToPage(n, event) {
      this.onPage = n;
      var elm = document.getElementById("top-of-list");
      elm.scrollIntoView();
    }

    get selectedFilters() {
      return this.educations.filter(e => e.selected);
    }

    get filteredItems() {
      return this.items.filter(e =>
        this.selectedFilters.map(ed => ed.id).includes(e.education_id)
      );
    }

    get numberOfPages() {
      return Math.ceil(this.filteredItems.length / this.perPage);
    }
  }
</script>

<style>
</style>
