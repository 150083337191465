export default () => {

  //
  // Mobile navigation toggle
  //

  // Find all toggles
  const toggle = document.querySelectorAll('[data-mobile-nav-toggle]');
  const list = document.querySelectorAll('[data-mobile-nav-sort]');

  // Reset
  document.documentElement.classList.remove('js-mobile-nav-open');

  // Assign click event listeners to all toggles
  for (var i = 0; i < toggle.length; i++) {
    toggle[i].addEventListener('click', function (e) {
      e.preventDefault();

      // Toggle nav with class on html
      document.documentElement.classList.toggle('js-mobile-nav-open');

      // Toggle aria-expanded value for all toggles
      var expandedState = this.getAttribute('aria-expanded') == "true" ? "false" : "true";

      for(i = 0; i < toggle.length; i++) {
        toggle[i].setAttribute("aria-expanded", expandedState);
      }
    });
  }

  // The list is an ul with li tags. Pick the li with the is-active class and move it to the top of the list.
  for (var i = 0; i < list.length; i++) {
    var activeItem = list[i].querySelector('.is-active');
    if (activeItem) {
      list[i].insertBefore(activeItem, list[i].firstChild);
    }
  }
}
