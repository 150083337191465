<template>
  <article class="col-lg-4 col-md-6 my-3 d-flex align-items-stretch">
    <a :href="interview.url" class="w-100 text-no-underline">
      <img :src="interview.image" width="480" class="card-img-top d-none d-md-block" />
      <div class="pt-3 d-flex flex-column justify-content-between">
        <div class="text-dark mt-n1">
          <h3 class="d-block">{{interview.name}}</h3>
          <span class="caption mb-2">{{interview.education}}</span>
          <p v-if="interview.lead" class="text-muted font-size-sm mb-0 d-none d-md-block">
            {{interview.lead}}
          </p>
        </div>
        <div class="mt-3 d-flex justify-content-between align-items-center">
          <span class="highlight-link-text font-size-base">
            Läs mer
          </span>
          <span class="ml-3 font-size-sm">
            <span class="svg-icon" aria-hidden="true" role="presentation"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15.73 8.75L9.12 2.13A1.25 1.25 0 0 1 10.88.37l8.75 8.75c.5.48.5 1.28 0 1.76l-8.75 8.75a1.25 1.25 0 0 1-1.76-1.76l6.61-6.62H1.25a1.25 1.25 0 0 1 0-2.5h14.48z"/></svg></span>
          </span>
        </div>
      </div>
    </a>
  </article>

</template>

<script>
  import { Vue, Component, Prop } from "vue-property-decorator";
  @Component({
    props: {
      interview: Object
    }
  })
  export default class InterviewCard extends Vue {
   
  }
</script>

<style>
</style>
